import { Component, OnInit, AfterViewInit } from '@angular/core';
import { TokenService } from 'src/app/core/service/token.service';
import { UserService } from 'src/app/core/service/http/user.service';
import { LoggerService } from 'src/app/core/service/logger.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

class SuccessResponse{
  errorCode: number;
  errorMessage: string;
  id: number;
  fullName: string;
  email: string;
}

class FormResponce{
  errorCode: number;
  errorMessage: string;
}

@Component({
  selector: 'app-user-edit-form',
  templateUrl: './user-edit-form.component.html',
  styleUrls: ['./user-edit-form.component.css']
})
export class UserEditFormComponent implements OnInit, AfterViewInit {
  form: FormGroup;
  userData: SuccessResponse = new SuccessResponse;
  formLoaded: boolean = true;
  showErrors: boolean = false;
  formError: string = "";

  constructor(public tokenService: TokenService, private userService: UserService,
              private logger: LoggerService, public translate: TranslateService) {
                this.createForm();
              }

  ngOnInit() {
    this.getUserInfo();
  }

  ngAfterViewInit(): void {
  }

  resetErrors(): void {
    this.showErrors = false;
    this.formError = '';
  }

  private getUserInfo(): void {
    this.userService.info()
      .subscribe((res: SuccessResponse) => {
        this.userData = res;
        this.form.get('id').setValue(this.userData.id);
        this.form.get('fullName').setValue(this.userData.fullName);
      }, (error: any) => {
        console.error(error);
        this.logger.handleError('user retrieve data error', localStorage.getItem('token'));
        this.tokenService.deauthorize();
      });
  }

  private createForm(): void {
    this.form = new FormGroup({
      id: new FormControl('', [Validators.required]),
      fullName: new FormControl('', [Validators.required]),
      oldPassword: new FormControl(''),
      newPassword: new FormControl('')
    })
  }
  get id() { return this.form.get('id'); }
  get fullName() { return this.form.get('fullName'); }
  get oldPassword() { return this.form.get('oldPassword'); }
  get newPassword() { return this.form.get('newPassword'); }

  public submit(): void {
    this.formLoaded = false;
    if (this.form.valid) {
      this.userService.update(this.form.value)
        .subscribe((res: FormResponce) => {
          switch (res.errorCode) {
            case 0:
              this.formError = res.errorMessage
              this.showErrors = true;
              this.formLoaded = true;
              return false;
            default:
              window.location.reload();
              return false;
          }
          // window.location.reload();
        }, (error: any) => {
          console.error(error);
          this.logger.handleError('update user form submit', this.form.value);
          this.formLoaded = true;
        });
    } else {
      this.showErrors = true;
      this.formLoaded = true;
    }
  }
}
 