import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  emitChange$: Subject<any> = new BehaviorSubject<any>(null);
  emitChange2$: Subject<any> = new BehaviorSubject<any>(null);
  constructor() { }
  emit(value: any) {
    this.emitChange$.next(value);
  }
  emit2(value: any) {
    this.emitChange2$.next(value);
  }
  get emitChange(): BehaviorSubject<any> {
    return (this.emitChange$ as BehaviorSubject<any>);
  }
  get emitChange2(): BehaviorSubject<any> {
    return (this.emitChange2$ as BehaviorSubject<any>);
  }
}