import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from 'src/app/core/service/http/login.service';
import { LoggerService } from 'src/app/core/service/logger.service';

class SuccessResponse{
  errorCode: number;
  errorMessage: string;
}

@Component({
  selector: 'app-proof-page',
  templateUrl: './proof-page.component.html',
  styleUrls: ['./proof-page.component.css']
})
export class ProofPageComponent implements OnInit {
  code: any;
  showError: boolean = false;
  error: string = "";

  constructor(private activatedRoute: ActivatedRoute, private loginService: LoginService,
              private logger: LoggerService, private router: Router) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.code = params['token'];
    });
  }

  ngOnInit() {
    this.check(this.code);
  }

  private check(code: string): void {
    this.loginService.proof(code)
      .subscribe((res: SuccessResponse) => {
        switch (res.errorCode) {
          case 0:
            this.router.navigate(['']);
            break;
          default:
            this.showError = true;
            this.error = res.errorMessage;
            break;
        }
      }, (error: any) => {
        this.logger.handleError('proof form', {
          activationCode: code
        });
      });
  }

}
