import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { apiRoutes } from 'src/app/config/apiRoutes';
import { TokenService } from '../token.service';

@Injectable({
  providedIn: 'root'
})
export class ReportHttpService {

  constructor(private http: HttpClient, private tokenService: TokenService) { }

  oneReport(data: any): any {
    let params = {
      params: data
    }

    let header = this.tokenService.getHeadersAuth().params = params;
    return this.http.get(apiRoutes.report.one, header);
  }
}
