import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenService } from '../token.service';
import { apiRoutes } from 'src/app/config/apiRoutes';

class UpdateFormReq{
  id: number;
  fullName: string;
  oldPassword: string;
  newPassword: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient, private tokenService: TokenService) { }

  info(): any {
    return this.http.get(apiRoutes.user.info, this.tokenService.getHeadersAuth());
  }

  update(form: UpdateFormReq): any {
    return this.http.put(apiRoutes.user.update, form, this.tokenService.getHeadersAuth());
  }
}
