import { Component, OnInit } from '@angular/core';
import { FoundationService } from '../../service/foundation.service';
import { TranslateService } from '@ngx-translate/core';
import { LangService } from '../../service/lang.service';
import { Subscription } from 'rxjs';
import { TokenService } from '../../service/token.service';
import { NotificationsService } from '../../service/notify.service';
import { UserService } from '../../service/http/user.service';
import { LoggerService } from '../../service/logger.service';

class SuccessResponse{
  errorCode: number;
  errorMessage: string;
  id: number;
  fullName: string;
  email: string;
}

@Component({
  selector: 'app-cabinet-template',
  templateUrl: './cabinet-template.component.html',
  styleUrls: ['./cabinet-template.component.css']
})
export class CabinetTemplateComponent implements OnInit {
  termSubscriber: Subscription;
  currentComponent: number;
  confirmExit: boolean = false;
  login: string;
  userData: any;

  constructor(private foundationService: FoundationService, public translate: TranslateService,
              public langService: LangService, private notificationsService: NotificationsService,
              public tokenService: TokenService, private userService: UserService,
              private logger: LoggerService) {
                this.notificationsService.emitChange.subscribe(
                  id => {
                    this.currentComponent = id;
                  }
                )
              }

  ngOnInit() {
    this.foundationService.ngOnInit();
    this.foundationService.dropdown();
    this.getUserInfo();
  }

  private getUserInfo(): void {
    this.userService.info()
      .subscribe((res: SuccessResponse) => {
        switch (res.errorCode) {
          case 403:
            this.tokenService.deauthorize();
            break;
          default:
            break;
        }
        this.login = res.email;
      }, (error: any) => {
        console.error(error);
        this.logger.handleError('user retrieve data error', localStorage.getItem('token'));
        this.tokenService.deauthorize();
      });
  }


}
