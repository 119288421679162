import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DataTablesModule } from 'angular-datatables';

import { AppComponent } from './app.component';
import { LoginTemplateComponent } from './core/page/login-template/login-template.component';
import { LoginPageComponent } from './core/page/login-template/login-page/login-page.component';
import { LoginFormComponent } from './core/component/login-form/login-form.component';

import { routes } from './config/routes';
import { PageNotFoundComponent } from './core/page/page-not-found/page-not-found.component';
import { CabinetTemplateComponent } from './core/page/cabinet-template/cabinet-template.component';
import { OnlyNumber } from './core/directive/OnlyNumber';
import { ForgetPageComponent } from './core/page/login-template/forget-page/forget-page.component';
import { ForgetFormComponent } from './core/component/forget-form/forget-form.component';
import { ProofPageComponent } from './core/page/login-template/proof-page/proof-page.component';
import { SelfulOrdersPageComponent } from './core/page/cabinet-template/orders/selful-orders-page/selful-orders-page.component';
import { SearchOrdersPageComponent } from './core/page/cabinet-template/orders/search-orders-page/search-orders-page.component';
import { UserEditPageComponent } from './core/page/cabinet-template/user-edit-page/user-edit-page.component';
import { UserEditFormComponent } from './core/component/user-edit-form/user-edit-form.component';
import { ReportsOrdersPageComponent } from './core/page/cabinet-template/orders/reports-orders-page/reports-orders-page.component';
import { OneReportPageComponent } from './core/page/cabinet-template/orders/reports-orders-page/one-report-page/one-report-page.component';

export function HttpLoaderFactory(http: HttpClient){
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginTemplateComponent,
    LoginPageComponent,
    LoginFormComponent,
    PageNotFoundComponent,
    CabinetTemplateComponent,
    OnlyNumber,
    ForgetPageComponent,
    ForgetFormComponent,
    ProofPageComponent,
    SelfulOrdersPageComponent,
    SearchOrdersPageComponent,
    UserEditPageComponent,
    UserEditFormComponent,
    ReportsOrdersPageComponent,
    OneReportPageComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(
      routes
    ),
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    DataTablesModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [],
  entryComponents: [
    SearchOrdersPageComponent,
    SelfulOrdersPageComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
