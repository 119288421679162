import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LangService {
  currentLanguage: string;

  constructor(private translate: TranslateService) { }

  getLangs(): any{
    return {
      'ru': 'Русский',
      'kk': 'Қазақ'
    }
  }

  getDatatablesLang(): any {
    switch (this.translate.currentLang) {
      case 'kk':
        return {
          search: "Іздеу",
          info: "_END_ жазбадан _START_-ден _END_-ке дейін көрсетілген",
          processing:     "Жүктеу",
          loadingRecords: "Жүктеу",
          infoEmpty:      "",
          infoFiltered:   "",
          zeroRecords:    " ",
          paginate: {
              "first":      "Бірінші",
              "last":       "Соңғы",
              "next":       "<i class='fa fa-angle-right'></i>",
              "previous":   "<i class='fa fa-angle-left'></i>"
          }
        }
      default:
        return {
          search: "Поиск",
          info: "Показано _START_ по _END_ из _TOTAL_ записей",
          processing:     "Загрузка",
          loadingRecords: "Загрузка",
          infoEmpty:      "",
          infoFiltered:   "",
          zeroRecords:    " ",
          paginate: {
              "first":      "Первый",
              "last":       "Последний",
              "next":       "<i class='fa fa-angle-right'></i>",
              "previous":   "<i class='fa fa-angle-left'></i>"
          }
        }
    }
  }

  getDatePickerLang(): any{
    switch (this.translate.currentLang) {
      case 'kk':
        return {
            previousMonth : 'Алдыңғы ай',
            nextMonth     : 'Келесі ай',
            months        : ['Қаңтар','Ақпан','Наурыз', 'Сәуір', 'Мамыр', 'Маусым', 'Шілде', 'Тамыз', 'Қыркүйек', 'Қазан', 'Қараша', 'Желтоқсан'],
            weekdays      : ['Жексенбі', 'Дүйсенбі', 'Сейсенбі', 'Сәрсенбі', 'Бейсенбі', 'Жұма', 'Сенбі'],
            weekdaysShort : ['Жек', 'Дүй','Сей','Сәр','Бей','Жұм','Сен']
        }
        break;
      default:
        return {
            previousMonth : 'Пред. месяц',
            nextMonth     : 'След. месяц',
            months        : ['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь'],
            weekdays      : ['Воскресенье', 'Понедельник','Вторник','Среда','Четверг','Пятница','Суббота'],
            weekdaysShort : ['Вс', 'Пн','Вт','Ср','Чт','Пт','Сб']
        }
        break;
    }
  }

  langSwitch(key: string): void {
    localStorage.setItem('lang', key);
    window.location.reload();
  }

  getCurrentLangName(): string {
    return this.getLangs()[this.translate.currentLang];
  }

  getCurrentLang(): string {
    return this.translate.currentLang;
  }

  init(): void {
    let lang = localStorage.getItem('lang');
    this.currentLanguage = (lang) ? lang : 'ru';

    
    this.translate.addLangs(['ru', 'kk']);
    this.translate.setDefaultLang(this.currentLanguage);
    this.translate.use(this.currentLanguage);
  }
}
