import { Routes, Router } from '@angular/router';
import { LoginGuard } from '../core/guard/login.guard';
import { LoginTemplateComponent } from '../core/page/login-template/login-template.component';
import { PageNotFoundComponent } from '../core/page/page-not-found/page-not-found.component';
import { CabinetTemplateComponent } from '../core/page/cabinet-template/cabinet-template.component';
import { AuthGuard } from '../core/guard/auth.guard';
import { LoginPageComponent } from '../core/page/login-template/login-page/login-page.component';
import { ForgetPageComponent } from '../core/page/login-template/forget-page/forget-page.component';
import { ProofPageComponent } from '../core/page/login-template/proof-page/proof-page.component';
import { SearchOrdersPageComponent } from '../core/page/cabinet-template/orders/search-orders-page/search-orders-page.component';
import { SelfulOrdersPageComponent } from '../core/page/cabinet-template/orders/selful-orders-page/selful-orders-page.component';
import { UserEditPageComponent } from '../core/page/cabinet-template/user-edit-page/user-edit-page.component';
import { ReportsOrdersPageComponent } from '../core/page/cabinet-template/orders/reports-orders-page/reports-orders-page.component';
import { OneReportPageComponent } from '../core/page/cabinet-template/orders/reports-orders-page/one-report-page/one-report-page.component';

let loginRoutes: Routes = [
    {
        path: '',
        component: LoginPageComponent
    },
    {
        path: 'forget',
        component: ForgetPageComponent
    }
]

let ordersRoutes: Routes = [
    {
        path: 'search',
        component: SearchOrdersPageComponent
    },
    {
        path: 'selful',
        component: SelfulOrdersPageComponent
    },
    {
        path: 'reports',
        component: ReportsOrdersPageComponent,
        children: [
            {
                path: '1',
                component: OneReportPageComponent
            }
        ]
    }
]

let cabinetRoutes: Routes = [
    {
        path: 'orders',
        children: ordersRoutes
    },
    {
        path: 'user/edit',
        component: UserEditPageComponent
    }
]

export const routes: Routes = [
    {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
    },
    {
        path: 'login',
        component: LoginTemplateComponent,
        canActivate: [LoginGuard],
        canActivateChild: [LoginGuard],
        children: loginRoutes 
    },
    {
        path: 'login/proof',
        component: ProofPageComponent,
        canActivate: [LoginGuard],
    },
    {
        path: 'cabinet',
        component: CabinetTemplateComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: cabinetRoutes
    },
    {
        path: '**',
        component: PageNotFoundComponent
    }
];
