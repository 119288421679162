import { Component, OnInit, OnDestroy } from '@angular/core';
import { NotificationsService } from 'src/app/core/service/notify.service';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormControl } from '@angular/forms';
import { OrderService } from '../../../../service/http/order.service';


@Component({
  selector: 'app-reports-orders-page',
  templateUrl: './reports-orders-page.component.html',
  styleUrls: ['./reports-orders-page.component.css']
})
export class ReportsOrdersPageComponent implements OnInit, OnDestroy {
  currentReport: string = null;
  order: FormGroup;

  



  constructor(private orderService: OrderService, private notificationsService: NotificationsService, private translate: TranslateService) {
  }

  ngOnDestroy() {
    this.currentReport = null;
  }

  ngOnInit() {
    this.order = new FormGroup({
      orderId: new FormControl()
    });
    this.notificationsService.emit(1);
    this.notificationsService.emitChange2.subscribe(
      report => {
        this.currentReport = report;
      }
    )
  }

  getKvitFile(){
    window.open('ppent.testcenter.kz/employee/cabinet/api/report/receipt?orderId=' + this.order.get('orderId').value, "_blank");
  }

  

}
