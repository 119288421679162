import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { OrderService } from 'src/app/core/service/http/order.service';
import { LoggerService } from 'src/app/core/service/logger.service';
import { TokenService } from 'src/app/core/service/token.service';
import { DateService } from 'src/app/core/service/date.service';
import { NotificationsService } from 'src/app/core/service/notify.service';

class SelfResponse{
  errorCode: number;
  result: any = {
    project_name_kaz: '',
    project_name_rus: '',
    quantity: 0,
    unitPrice: 0.0,
    orderStatusId: 0,
    orderStatusNameKaz: '',
    orderStatusNameRus: '',
    orderCreateDateTime: '',
    email: '',
    fullName: ''
  }
}

class SuccessCloseResponse{
  errorCode: number;
  errorMessage: string;
}

@Component({
  selector: 'app-search-orders-page',
  templateUrl: './search-orders-page.component.html',
  styleUrls: ['./search-orders-page.component.css']
})
export class SearchOrdersPageComponent implements OnInit {
  searchForm: FormGroup;
  showErrors = false;
  searchFormError: string = '';
  formSubmitLoaded: boolean = true;
  results: SelfResponse['result'];
  showResult: boolean = false;
  orderId: number = 0;
  confirmEndOrder = false;
  searchErrors: any = {
    notFound: false
  };
  closeLoaded: boolean = true;

  constructor(public translate: TranslateService, private orderService: OrderService,
              private logger: LoggerService, private tokenService: TokenService,
              public dateService: DateService, private notificationsService: NotificationsService) {
                this.createForm();
                this.notificationsService.emit(1);
              }

  ngOnInit() {
  }

  private createForm(): void {
    this.searchForm = new FormGroup({
      id: new FormControl('', [Validators.required])
    });
  }
  get id() {
    return this.searchForm.get('id');
  }

  resetErrors(): void {
    this.showErrors = false;
    this.searchFormError = '';
  }

  closeOrder(): void {
    this.closeLoaded = false;
    this.orderService.closeOrder(this.orderId)
      .subscribe((data: SuccessCloseResponse) => {
        if (data.errorCode == 0){
          this.results.orderStatusId = 4;
          this.results.orderStatusNameRus = 'Завершен';
          this.results.orderStatusNameKaz = 'Аяқталды';
          this.closeLoaded = true;
        }
        console.log(data);
        this.closeLoaded = true;
      }, (error: any) => {
          console.error(error);
          this.logger.handleError('search order form', this.searchForm.value);
          this.closeLoaded = true;
      });
  }

  submit(): void {
    this.formSubmitLoaded = false;
    this.showResult = false;
    this.orderId = +this.searchForm.get('id').value;
    this.results = SelfResponse['result'];
    if(this.searchForm.valid){
      this.orderService.findById(this.orderId)
        .subscribe((data: any) => {
          switch (data.errorCode) {
            case 0:
              this.results = data.result;
              this.showResult = true;
              break;
            default:
              this.showErrors = true;
              this.searchFormError = data.errorMessage;
              break;
          }

          this.formSubmitLoaded = true;
          this.confirmEndOrder = false;
        }, (_error: any) => {
          this.confirmEndOrder = false;
          this.tokenService.deauthorize();
          this.logger.handleError('search order form', this.searchForm.value);
          this.formSubmitLoaded = true;
          this.confirmEndOrder = false;
        });
    } else {
      this.confirmEndOrder = false;
      this.formSubmitLoaded = true;
      this.showErrors = true;
    }
  }
}
