import { Injectable } from '@angular/core';
import { apiRoutes } from '../../../config/apiRoutes';
import { HttpClient } from '@angular/common/http';
import { TokenService } from '../token.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  constructor(private http: HttpClient, private tokenService: TokenService) { }

  findById(id: number): any {
    return this.http.get(apiRoutes.order.byId + '?id=' + id, this.tokenService.getHeadersAuth());
  }

  closeOrder(id: number): any {
    return this.http.post(apiRoutes.order.close + '?id=' + id, {}, this.tokenService.getHeadersAuth());
  }

  getKvitFile(id: number): any {
    return this.http.get(apiRoutes.checklist.pricelist + id, this.tokenService.getHeadersAuth());
  }

}
