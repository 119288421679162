import { Injectable, OnInit } from '@angular/core';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class FoundationService implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
    $(document).foundation();
  }

  dropdown(): void {
    $('.is-dropdown-submenu-parent').hover(function(){
        $(this).children('a').children('i.fa-angle-down').removeClass('fa-angle-down').addClass('fa-angle-up');
    }, function() {
        $(this).children('a').children('i.fa-angle-up').removeClass('fa-angle-up').addClass('fa-angle-down');
    });
  }
}
