import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ChangerService } from 'src/app/core/service/changer.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { apiRoutes } from 'src/app/config/apiRoutes';
import { LangService } from 'src/app/core/service/lang.service';
import { TranslateService } from '@ngx-translate/core';
import { DateService } from 'src/app/core/service/date.service';
import { NotificationsService } from 'src/app/core/service/notify.service';

class Data{
  id: number;
  project_name_kaz: string;
  project_name_rus: string;
  quantity: number;
  unitPrice: DoubleRange;
  orderStatusId: number;
  orderStatusNameKaz: string;
  orderStatusNameRus: string;
  orderCreateDateTime: string;
  orderCloseDateTime: string;
  email: string;
  fullName: string;
}

class Order{
  errorCode: number;
  errorMessage: string;
  draw: number;
  recordsTotal: number;
  recordsFiltered: number;
  data: Data[];
}

@Component({
  selector: 'app-selful-orders-page',
  templateUrl: './selful-orders-page.component.html',
  styleUrls: ['./selful-orders-page.component.css']
})
export class SelfulOrdersPageComponent implements OnInit, AfterViewInit, OnDestroy{
  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  orders: any;

  constructor(private datatableChanger: ChangerService, private http: HttpClient,
              private langService: LangService, public translate: TranslateService,
              public dateService: DateService, private notificationsService: NotificationsService) {
                this.notificationsService.emit(1);
              }

  ngOnInit() {
    this.retrieveData();
    
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }

  private rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  private retrieveData(): void {
    let that = this;

    let name = {data: ''};
    let orderStatusName = {data: ''};
    if (this.translate.currentLang == 'kk'){
      name.data = 'project_name_kaz';
    } else {
      name.data = 'project_name_rus';
    }

    this.dtOptions = {
      pageLength: 25,
      lengthChange: false,
      autoWidth: false,
      order: [[3, "desc"]],
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        let req = that.datatableChanger.changeDatatablesRequest(dataTablesParameters);
        that.http.get(apiRoutes.order.getWithPageable + '?' + that.datatableChanger.changeParam(req),
          {
            headers: new HttpHeaders().set('token', localStorage.getItem('token'))
          }
        ).subscribe((res: Order) => {
          that.orders = res.data;
          callback({
            recordsTotal: res.recordsTotal,
            recordsFiltered: res.recordsFiltered,
            data: []
          })
        })
      },
      columns: [
        {data: 'id'},
        name,
        {data: 'orderCreateDateTime'},
        {data: 'orderCloseDateTime'},
        {data: 'email'},
        {data: 'fullname'},
        {data: 'quantity'},
        {data: 'unitPrice'},
        {data: null}
      ],
      columnDefs: [
        {
          targets: 8,
          orderable: false,
          searchable: false
        },
        {
          targets: 1,
          orderable: false
        }
      ],
      language: that.langService.getDatatablesLang()
    }
  }

}
