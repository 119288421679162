import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  toNormalDate(date: string): string {
    return date.split(',')[0];
  }
}
