import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoginService } from '../../service/http/login.service';
import { LoggerService } from '../../service/logger.service';
import { TranslateService } from '@ngx-translate/core';
import { TokenService } from '../../service/token.service';

class SuccessLoginResponse {
  errorCode: number;
  errorMessage: string;
  token: string;
}

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css']
})
export class LoginFormComponent implements OnInit {
  form: FormGroup;
  formError: string = '';
  formSubmitLoaded: boolean = true;
  showErrors: boolean = false;

  constructor(private loginService: LoginService, private logger: LoggerService,
              public translate: TranslateService, private tokenService: TokenService) {
    this.createForm();
  }

  ngOnInit() {
  }

  private createForm(): void {
    this.form = new FormGroup({
      login: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(4)])
    })
  }
  get login() { return this.form.get('login'); }
  get password() { return this.form.get('password'); }


  resetErrors(): void {
    this.showErrors = false;
    this.formError = '';
  }

  submit(): void {
    this.formSubmitLoaded = false;
    if(this.form.valid){
      this.loginService.auth(this.form.value)
        .subscribe((res: SuccessLoginResponse) => {
          if (res.errorCode != 0) {
            this.showErrors = true;
            this.formError = res.errorMessage;
            this.formSubmitLoaded = true;
            return false;
          }
          this.tokenService.authorize(res.token);
          this.formSubmitLoaded = true;
        }, error => {
          console.error(error);
          this.logger.handleError('login form submit', this.form.value);
          this.formSubmitLoaded = true;
        });
    } else {
      this.showErrors = true;
      this.formSubmitLoaded = true;
    }
  }
}
