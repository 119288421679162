// const location = 'http://192.168.200.15:28080/';
const location = '../../';
const prefix = 'employee/cabinet/api/';

export const apiRoutes: any = {
    auth: {
        login: location + prefix + 'user/login',
        proof: location + prefix + 'registration/activate',
        forget: location + prefix + 'user/forgotPassword'
    },
    order: {
        byId: location + prefix + 'order/getById',
        close: location + prefix + 'order/closeById',
        getWithPageable: location + prefix + 'order/getAll'
    },
    user: {
        info: location + prefix + 'user/getProfile',
        update: location + prefix + 'user/updateProfile'
    },
    report: {
        one: location + prefix + 'report/byRealization'
    },
    checklist: {
        pricelist: location + prefix + 'report/receipt?orderId='
    }
} 