import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { apiRoutes } from '../../../config/apiRoutes';
import { TokenService } from '../token.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(private http: HttpClient, private tokenService: TokenService) {
  }

  forget(email: string): any {
    return this.http.get(apiRoutes.auth.forget + '?email=' + email, this.tokenService.getHeadersNonAuth());
  }

  proof(activationCode: string): any {
    return this.http.get(apiRoutes.auth.proof + '?activationCode=' + activationCode, this.tokenService.getHeadersNonAuth());
  }

  auth(data: {login: string, password: string}): any {
    return this.http.post(apiRoutes.auth.login, data, this.tokenService.getHeadersNonAuth());
  }
}
