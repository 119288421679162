import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LangService } from './lang.service';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(private router: Router, private langService: LangService) { }

  authorize(token: string): void {
    localStorage.setItem('token', token);
    this.router.navigate(['/cabinet/orders/search']);
  }

  deauthorize(): void {
    localStorage.removeItem('token');
    this.router.navigate(['/']);
  }

  getHeadersNonAuth(): any {
    const requestOption = { 
      headers:
      {
        'Accept-Language': this.langService.getCurrentLang()
      }
    };
    
    return requestOption;
  }

  getHeadersAuth(): any {
    const requestOption = { 
      headers: 
      {
        'Accept-Language': this.langService.getCurrentLang(),
        'token':  localStorage.getItem('token')
      }
    };
    return requestOption;
  }
}
