import { Component, OnInit } from '@angular/core';
import { FoundationService } from '../../service/foundation.service';
import { TranslateService } from '@ngx-translate/core';
import { LangService } from '../../service/lang.service';

@Component({
  selector: 'app-login-template',
  templateUrl: './login-template.component.html',
  styleUrls: ['./login-template.component.css']
})
export class LoginTemplateComponent implements OnInit {

  constructor(private foundationService: FoundationService, public translate: TranslateService,
              public langService: LangService) {
  }

  ngOnInit() {
    this.foundationService.ngOnInit();
    this.foundationService.dropdown();
  }

}
