import { Injectable } from '@angular/core';
import * as qs from 'qs';

@Injectable({
  providedIn: 'root'
})
export class ChangerService {

  constructor() { }

  changeDatatablesRequest(req: any): any {
    let newReq = {
      draw: req.draw,
      start: req.start,
      rowPerPage: req.length,
      columnName: req.columns[req.order[0].column].data,
      columnSortOrder: req.order[0].dir,
      searchValue: req.search.value
    }
    return newReq;
  }

  changeParam(param: any): any {
    return qs.stringify(param);
  }
}
