import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NotificationsService } from 'src/app/core/service/notify.service';
import * as Pikaday from 'pikaday';
import { LangService } from 'src/app/core/service/lang.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ReportHttpService } from 'src/app/core/service/http/report-http.service';
import { LoggerService } from 'src/app/core/service/logger.service';
import { UserService } from 'src/app/core/service/http/user.service';
import { TokenService } from 'src/app/core/service/token.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

declare var $;
declare var Stimulsoft: any;

@Component({
  selector: 'app-one-report-page',
  templateUrl: './one-report-page.component.html',
  styleUrls: ['./one-report-page.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class OneReportPageComponent implements OnInit {
  reportForm: FormGroup;
  showErrors = false;
  reportFormSubmitted = false;
  formError: string = '';

  constructor(private notificationService: NotificationsService, private langService: LangService,
              private http: HttpClientModule, private reportService: ReportHttpService,
              private logger: LoggerService, private userService: UserService,
              private tokenService: TokenService, public translate: TranslateService) {
    this.notificationService.emit2('one');
  }

  ngOnInit() {
    let langs = this.langService.getDatePickerLang();
    new Pikaday(
      { 
        field: document.getElementById('datepicker-start'),
        firstDay: 1,
        minDate: new Date(2000, 0, 1),
        maxDate: new Date(),
        yearRange: [2000, 2020],
        format: "DD.MM.YYYY",
        i18n:
          langs
      }
    );

    new Pikaday(
      { 
        field: document.getElementById('datepicker-end'),
        firstDay: 1,
        minDate: new Date(2000, 0, 1),
        maxDate: new Date(),
        yearRange: [2000, 2020],
        format: "DD.MM.YYYY",
        i18n:
          langs
      }
    );

    this.createReportForm();

    $(document).ready(function(){
      $('.datepicker').on('change', function(e) {
        this.reportForm.get('dateFrom').setValue($("#datepicker-start").val());
        this.reportForm.get('dateTo').setValue($("#datepicker-end").val());
      }.bind(this));
    }.bind(this));

    this.getUserId();
  }

  private getUserId(){
    this.userService.info()
      .subscribe((res: any) => {
        this.reportForm.get('userId').setValue(res.id);
      }, (error: any) => {
        this.tokenService.deauthorize();
        this.logger.handleError('report one', this.reportForm.value);
      });
  }

  private createReportForm(): void {
    this.reportForm = new FormGroup({
      userId: new FormControl("", []),
      dateFrom: new FormControl('', [Validators.required]),
      dateTo: new FormControl('', [Validators.required])
    });
  }
  get userId() { return this.reportForm.get('userId'); }
  get dateFrom() { return this.reportForm.get('dateFrom'); }
  get dateTo() { return this.reportForm.get('dateTo'); }

  submit(): void {
    this.reportFormSubmitted = true;
    if(this.reportForm.valid){
      let req = {
        userId: this.userId.value,
        dateFrom: this.dateFrom.value + ', 00:00',
        dateTo: this.dateTo.value + ', 23:59'
      }
      this.reportService.oneReport(req)
        .subscribe((res: any) => {
          switch (res.errorCode) {
            case 0:
              let json = {
                data: res.data,
                title: res.title,
                today: {
                  today: this.dCreate()
                }
              }
              this.getFile(json);
              this.reportFormSubmitted = false;
              break;
          
            default:
              this.showErrors = true;
              this.formError = res.errorMessage;
              break;
          }
        }, (error: any) => {
          this.showErrors = true;
          this.logger.handleError('report one', this.reportForm.value);
          this.reportFormSubmitted = false;
        });
    } else {
      this.showErrors = true;
      this.reportFormSubmitted = false;
    }
  }

  resetErrors(): void {
    this.showErrors = false;
    this.formError = '';
  }

  private getFile(json): void {
    let report = new Stimulsoft.Report.StiReport();
    let that = this;
    report.loadFile("assets/1Report.mrt");
    
    let dataSet = new Stimulsoft.System.Data.DataSet("data");
    dataSet.readJson(json);
    report.dictionary.databases.clear();
    report.regData(dataSet.dataSetName, "", dataSet);
    
    report.dictionary.synchronize();
   
    report.renderAsync(function () {
			var data = report.exportDocument(Stimulsoft.Report.StiExportFormat.Pdf);
			(<any>Object).saveAs(data, that.dCreate() + "-report.pdf", "application/pdf");
		})
  }

  private dCreate(): string {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    var todayName = dd + '.' + mm + '.' + yyyy;
    return todayName;
  }
}
